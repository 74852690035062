import React from "react"
import { useIntl } from "gatsby-plugin-intl"
import HeroImage from '../images/hero_image'
import './hero.scss'
import SanitizedHTML from 'react-sanitized-html';
import { graphql, useStaticQuery } from "gatsby"

export default function Hero() {
  const intl = useIntl()
  const heroData =  useStaticQuery(graphql`
    query {
      pageTranslations {
        pageTranslations {
          disclaimer
          welcome
          disclaimerBody
          disclaimerButton
          exploreBody
          exploreHeader
          heroTitle
          heroBody
          heroButton
          glossary
          localization {
            culture
          }
        }
      }
    }
  `);
  
  function languageMap() {
    let translations =  heroData.pageTranslations.pageTranslations;
    switch (intl.locale) {
      case 'es':
        return translations.filter(hero => hero.localization.culture === 'es').map(hero => ({
          title: hero.heroTitle,
          body: hero.heroBody,
          button: hero.heroButton,
        }));
      case 'zh':
        return translations.filter(hero => hero.localization.culture === 'zh').map(hero => ({
          title: hero.heroTitle,
          body: hero.heroBody,
          button: hero.heroButton,
        }));
      case 'ht':
        return translations.filter(hero => hero.localization.culture === 'fr-HT').map(hero => ({
          title: hero.heroTitle,
          body: hero.heroBody,
          button: hero.heroButton,
        }));
      case 'pt':
        return translations.filter(hero => hero.localization.culture === 'pt').map(hero => ({
          title: hero.heroTitle,
          body: hero.heroBody,
          button: hero.heroButton,
        }));
      case 'pa':
        return translations.filter(hero => hero.localization.culture === 'pa').map(hero => ({
          title: hero.heroTitle,
          body: hero.heroBody,
          button: hero.heroButton,
        }));
      default: 
        return translations.filter(hero => hero.localization.culture === 'en-US').map(hero => ({
          title: hero.heroTitle,
          body: hero.heroBody,
          button: hero.heroButton,
        }));
    }
  }
    return (
    <section className="usa-hero--doj" aria-label="Introduction">
      <HeroImage style={{ position: `absolute`, top: `0`, left: `0`, right: `0`, height: `600px`, zIndex: `-1` }}/>
      <div style={{ display: `flex`, justifyContent: `center`, alignItems: `center`, width: `100%`, height: `100%` }}>
        <div className="grid-container">
          <div className="icor-hero__callout">
            <h1 className="usa-hero__heading">
                {languageMap()[0]?.title}
            </h1>
            <SanitizedHTML html={languageMap()[0]?.body}/>
            <a className="icor-btn" style={{display: 'inline-flex', marginTop: '2rem'}} href="#explore">{languageMap()[0]?.button}</a>
          </div>
        </div>
      </div>
    </section>
    )
}