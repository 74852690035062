import React, { useState, useEffect } from "react"
import { useIntl } from "gatsby-plugin-intl"
import exploreStyles from './explore.module.scss'
import { graphql, useStaticQuery } from "gatsby"
import SanitizedHTML from 'react-sanitized-html';

export default function Explore(props) {
    const intl = useIntl();
    const cardData =  useStaticQuery(graphql`
        query {
            cards {
                cards(orderBy:{latest:DESC}) {
                    contentItemId
                    displayText
                    titleEnglish
                    titleSpanish
                    titleChinese
                    titleHaitianCreole
                    titlePortuguese
                    titlePunjabi
                    bodyEnglish
                    bodySpanish
                    bodyChinese
                    bodyHaitianCreole
                    bodyPortuguese
                    bodyPunjabi
                    buttonEnglish
                    buttonSpanish
                    buttonChinese
                    buttonHaitianCreole
                    buttonPortuguese
                    buttonPunjabi
                    solutions {
                        contentItemIds
                    }
                }
                pageTranslations {
                  exploreHeader
                  exploreBody
                  localization {
                    culture
                  }
                }
            }
        }
    `);

    function exploreMap() {
      let translations =  cardData.cards.pageTranslations;
      switch (intl.locale) {
        case 'es':
          return translations.filter(hero => hero.localization.culture === 'es').map(page => ({
            title: page.exploreHeader,
            body: page.exploreBody,
          }))
        case 'zh':
          return translations.filter(hero => hero.localization.culture === 'zh').map(page => ({
            title: page.exploreHeader,
            body: page.exploreBody,
          }))
        case 'ht':
          return translations.filter(hero => hero.localization.culture === 'fr-HT').map(page => ({
            title: page.exploreHeader,
            body: page.exploreBody,
          }))
        case 'pt':
          return translations.filter(hero => hero.localization.culture === 'pt').map(page => ({
            title: page.exploreHeader,
            body: page.exploreBody,
          }))
        case 'pa':
          return translations.filter(hero => hero.localization.culture === 'pa').map(page => ({
            title: page.exploreHeader,
            body: page.exploreBody,
          }))
        default: 
        return translations.filter(hero => hero.localization.culture === 'en-US').map(page => ({
          title: page.exploreHeader,
          body: page.exploreBody,
        }))
      }
    }

    function languageMap() {
      switch (intl.locale) {
        case 'es':
          return cardData.cards.cards.map(card => ({
            id: card.contentItemId,
            title: card.titleSpanish || card.titleEnglish,
            body: card.bodySpanish || card.bodyEnglish,
            button: card.buttonSpanish || card.buttonEnglish,
            solutions: card.solutions.contentItemIds
          }))
        case 'zh':
          return cardData.cards.cards.map(card => ({
            id: card.contentItemId,
            title: card.titleChinese || card.titleEnglish,
            body: card.bodyChinese || card.bodyEnglish,
            button: card.buttonChinese || card.buttonEnglish,
            solutions: card.solutions.contentItemIds
          }))
        case 'ht':
          return cardData.cards.cards.map(card => ({
            id: card.contentItemId,
            title: card.titleHaitianCreole || card.titleEnglish,
            body: card.bodyHaitianCreole || card.bodyEnglish,
            button: card.buttonHaitianCreole || card.buttonEnglish,
            solutions: card.solutions.contentItemIds
          }))
        case 'pt':
          return cardData.cards.cards.map(card => ({
            id: card.contentItemIds,
            title: card.titlePortuguese || card.titleEnglish,
            body: card.bodyPortuguese || card.bodyEnglish,
            button: card.buttonPortuguese || card.buttonEnglish,
            solutions: card.solutions.contentItemIds
          }))
        case 'pa':
          return cardData.cards.cards.map(card => ({
            id: card.contentItemId,
            title: card.titlePunjabi || card.titleEnglish,
            body: card.bodyPunjabi || card.bodyEnglish,
            button: card.buttonPunjabi || card.buttonEnglish,
            solutions: card.solutions.contentItemIds
          }))
        default: 
          return cardData.cards.cards.map(card => ({
            id: card.contentItemId,
            title: card.titleEnglish,
            body: card.bodyEnglish,
            button: card.buttonEnglish,
            solutions: card.solutions.contentItemIds
          }))
      }
    }
    
    return (
      <section className="grid-container" id="explore">
        <div className="text-center margin-y-9">
          <h2 className="font-serif-xl tablet:font-serif-2xl">{exploreMap()[0].title}</h2>
          <p className="font-sans-md">{exploreMap()[0].body}</p>
        </div>
        <div className={`${exploreStyles.flex} flex-justify-center`}>
            
        {languageMap().map((card, index) => {
            return(
              <div className={`${exploreStyles.card} ${props.active === index ? exploreStyles.cardActive : '' }`} key={card.title}>
                <div className={exploreStyles.card__text}>
                  <h2 className="margin-0"><SanitizedHTML html={card.title}/></h2>
                  <div className="margin-y-4"><SanitizedHTML html={card.body}/></div>
                  <button type="button" className={exploreStyles.usaButton} onClick={() => { 
                    document.getElementById("tree").scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});
                    props.setSolutionsPath(card.solutions);
                    props.setSolutionsTitle(card.title);
                    props.setActive(index);
                  }}><SanitizedHTML html={card.button}/></button>
                </div>
              </div>
            )
        })}
   
        </div>
      </section>
    );
}