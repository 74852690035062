import React, { useState, useEffect, useRef } from "react"
import { useIntl, FormattedMessage  } from "gatsby-plugin-intl"
import SanitizedHTML from 'react-sanitized-html';
import solutionStyles from './solutions.module.scss'
import Modal from 'react-modal';
import ScrollToTop from "../components/scrollToTop";
import { graphql, useStaticQuery } from "gatsby"

Modal.setAppElement('#___gatsby')
const customStyles = {
  content : {
    top                   : '50%',
    left                  : '50%',
    right                 : 'auto',
    bottom                : 'auto',
    marginRight           : '-50%',
    transform             : 'translate(-50%, -50%)',
    maxHeight             : '90vh',
    maxWidth              : '90vw',
    fontSize              : '18px',
    backgroundColor       : '#ffffff'
  }
};
Modal.defaultStyles.overlay.backgroundColor = 'rgba(51, 51, 51, 0.77)';
Modal.defaultStyles.content.border = '0';
Modal.defaultStyles.content.borderRadius = '0';
Modal.defaultStyles.content.padding = '0';
Modal.defaultStyles.content.backgroundColor = '#FFFFFF';

const allowedHtml = {
  allowedTags: [
  "address", "article", "aside", "footer", "header", "h1", "h2", "h3", "h4",
  "h5", "h6", "main", "nav", "section", "blockquote", "dd", "div", "iframe",
  "dl", "dt", "figcaption", "figure", "hr", "li", "main", "ol", "p",
  "ul", "a", "abbr", "b", "br","em", "i", "mark", "small", "span", "strong", "time", 
  "caption", "col", "colgroup", "table", "tbody", "td", "tfoot", "th", "thead", "tr"
  ],
  allowedAttributes: {
    'a': [ 'href', 'name', 'target', 'rel' ],
    'img': [ 'src' ],
    'iframe': ['src', 'allow']
  },
  allowedIframeHostnames: ['www.youtube.com']
}


export default function Solutions(props) {
  const intl = useIntl();
  const modalRef = useRef(null);
  const solutionData =  useStaticQuery(graphql`
        query {
          glossary {
            glossary {
              contentItemId
              termEnglish
              termSpanish
              termChinese
              termHaitianCreole
              termPortuguese
              termPunjabi
              termChinese
              definitionEnglish
              definitionChinese
              definitionSpanish
              definitionHaitianCreole
              definitionPortuguese
              definitionPunjabi
            }
          },
          solutions {
            solutions(first:500) {
              contentItemId
              titleEnglish
              titleSpanish
              titleChinese
              titleHaitianCreole
              titlePortuguese
              titlePunjabi
              bodyEnglish
              bodySpanish
              bodyChinese
              bodyHaitianCreole
              bodyPortuguese
              bodyPunjabi
              children {
                contentItemIds
              }
              glossaryTerms {
                contentItemIds
              }
            }
          },
          pageTranslations {
            pageTranslations {
              solutionSelectDisclaimer
              glossary
              localization {
                culture
              }
            }
          }
        }
    `);

  // opens solution if url has solution guid in its parameters by setting default state to correct solution
  let titleState = '';
  let treeState = [];
  let modalState = false;
  
  const isBrowser = typeof window !== "undefined";
  if (isBrowser) {
    const queryParams = new URLSearchParams(window.location.search);
    const solutionId = queryParams.get('solution');
    if(solutionId !== null) {
      const urlSolution = languageMap().filter(card => props.solutionsPath?.includes(card.contentItemId)).reverse().find(solution => solution.contentItemId === solutionId);
      if(urlSolution !== undefined){
        titleState = urlSolution.title
        treeState = [{...urlSolution, parent: '0'}];
        modalState = true;
      }
    }
  }
  
  const [title, setTitle] = useState(titleState);
  const [solutionTree, setSolutionTree] = useState(treeState);
  const [modalIsOpen, setIsOpen] = useState(modalState);
  const [glossary, setGlossary] = useState([]);

  useEffect(() => {
    if(document.getElementById('solution-modal')){
      // const modal = document.getElementById('solution-modal');
      // modal.scrollTop = modal.scrollHeight - modal.clientHeight - 96;
    }
    if (isBrowser) {
      const queryParams = new URLSearchParams(window.location.search);
      const solutionId = queryParams.get('solution');
      if(solutionId !== null){
        const urlSolution = languageMap().filter(card => props.solutionsPath?.includes(card.contentItemId)).reverse().find(solution => solution.contentItemId === solutionId);
        if(urlSolution !== undefined){
          document.getElementById("tree").scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});
        }
      }
    }
    if(document.querySelectorAll('.latest-solution').length > 1){
      const latest = document.querySelectorAll('.latest-solution');
      latest[latest.length-2].scrollIntoView({behavior:"smooth", block: "start", inline: "nearest"})
    }
  });


  useEffect(() => handleGlossary(), [solutionTree]);

  function languageMap() {
    switch (intl.locale) {
      case 'es':
        return solutionData.solutions.solutions.map(card => ({
          contentItemId: card.contentItemId,
          title: card.titleSpanish || card.titleEnglish,
          body: card.bodySpanish || card.bodyEnglish,
          children: card.children?.contentItemIds || [],
          terms: glossaryMap(card.glossaryTerms?.contentItemIds, 'es')
        }))
      case 'zh':
        return solutionData.solutions.solutions.map(card => ({
          contentItemId: card.contentItemId,
          title: card.titleChinese || card.titleEnglish,
          body: card.bodyChinese || card.bodyEnglish,
          children: card.children?.contentItemIds || [],
          terms: glossaryMap(card.glossaryTerms?.contentItemIds, 'zh')
        }))
      case 'ht':
        return solutionData.solutions.solutions.map(card => ({
          contentItemId: card.contentItemId,
          title: card.titleHaitianCreole || card.titleEnglish,
          body: card.bodyHaitianCreole || card.bodyEnglish,
          children: card.children?.contentItemIds || [],
          terms: glossaryMap(card.glossaryTerms?.contentItemIds, 'ht')
        }))
      case 'pt':
        return solutionData.solutions.solutions.map(card => ({
          contentItemId: card.contentItemId,
          title: card.titlePortuguese || card.titleEnglish,
          body: card.bodyPortuguese || card.bodyEnglish,
          children: card.children?.contentItemIds || [],
          terms: glossaryMap(card.glossaryTerms?.contentItemIds, 'pt')
        }))
      case 'pa':
        return solutionData.solutions.solutions.map(card => ({
          contentItemId: card.contentItemId,
          title: card.titlePunjabi || card.titleEnglish,
          body: card.bodyPunjabi || card.bodyEnglish,
          children: card.children?.contentItemIds || [],
          terms: glossaryMap(card.glossaryTerms?.contentItemIds, 'pa')
        }))
      default: 
        return solutionData.solutions.solutions.map(card => ({
          contentItemId: card.contentItemId,
          title: card.titleEnglish,
          body: card.bodyEnglish,
          children: card.children?.contentItemIds || [],
          terms: glossaryMap(card.glossaryTerms?.contentItemIds, 'en') 
        }))
    }
  }

  function wordGlossary() {
    const pageTranslations = solutionData.pageTranslations.pageTranslations;
    const english = pageTranslations.filter(page => page.localization.culture === 'en-US')[0].glossary;
    switch (intl.locale) {
      case 'es':
        return pageTranslations.filter(page => page.localization.culture === 'es')[0].glossary || english
      case 'zh':
        return pageTranslations.filter(page => page.localization.culture === 'zh')[0].glossary || english
      case 'ht':
        return pageTranslations.filter(page => page.localization.culture === 'fr-HT')[0].glossary || english
      case 'pt':
        return pageTranslations.filter(page => page.localization.culture === 'pt')[0].glossary || english
      case 'pa':
        return pageTranslations.filter(page => page.localization.culture === 'pa')[0].glossary || english
      default: 
        return english
    }
  }

  function selectText() {
    const pageTranslations = solutionData.pageTranslations.pageTranslations;
    const english = pageTranslations.filter(page => page.localization.culture === 'en-US')[0].solutionSelectDisclaimer;
    switch (intl.locale) {
      case 'es':
        return pageTranslations.filter(page => page.localization.culture === 'es')[0].solutionSelectDisclaimer || english
      case 'zh':
        return pageTranslations.filter(page => page.localization.culture === 'zh')[0].solutionSelectDisclaimer || english
      case 'ht':
        return pageTranslations.filter(page => page.localization.culture === 'fr-HT')[0].solutionSelectDisclaimer || english
      case 'pt':
        return pageTranslations.filter(page => page.localization.culture === 'pt')[0].solutionSelectDisclaimer || english
      case 'pa':
        return pageTranslations.filter(page => page.localization.culture === 'pa')[0].solutionSelectDisclaimer || english
      default: 
        return english
    }
  }

  function glossaryMap(ids, locale) {
    if(ids){
      return solutionData.glossary.glossary.filter(term => ids.includes(term.contentItemId)).map(term => {
        switch (locale) {
          case 'es':
            return {
              id: term.contentItemId,
              term: term.termSpanish || term.termEnglish,
              definition: term.definitionSpanish || term.definitionEnglish
            }
          case 'zh':
            return {
              id: term.contentItemId,
              term: term.termChinese || term.termEnglish,
              definition: term.definitionChinese || term.definitionEnglish
            }
          case 'ht':
            return {
              id: term.contentItemId,
              term: term.termHaitianCreole || term.termEnglish,
              definition: term.definitionHaitianCreole || term.definitionEnglish
            }
          case 'pt':
            return {
              id: term.contentItemId,
              term: term.termPortuguese || term.termEnglish,
              definition: term.definitionPortuguese || term.definitionEnglish
            }
          case 'pa':
            return {
              id: term.contentItemId,
              term: term.termPunjabi || term.termEnglish,
              definition: term.definitionPunjabi || term.definitionEnglish
            }
          default:
            return {
              id: term.contentItemId,
              term: term.termEnglish,
              definition: term.definitionEnglish
            }
        }
      })
    } else {
      return [];
    }
  }

  function handleOption(solution) {
    setTitle(solution.title);
    setSolutionTree([{...solution, parent: '0'}]);
    setIsOpen(true);
  }

  function handleSolutionChildren(child) {
    // check if another button in the same group is clicked
    if(solutionTree.filter(sol => sol.parent === child.parent).length > 0){
      //  clear other options from array and add the new clicked button
      setSolutionTree([...solutionTree.slice(0, solutionTree.findIndex(sol => sol.contentItemId === child.parent)+1), child]);
    } else {
      setSolutionTree([...solutionTree, child]);
    }
  }

  function handleGlossary() {
    // takes every glossary term from every solution that was clicked on and filters out the dupes
    const uniqueTerms = solutionTree.map(solution => solution.terms).flat().filter((term, index, terms) =>
      index === terms.findIndex(word => word.id === term.id)
    ).sort((a, b) => a.term.localeCompare(b.term));
    setGlossary(uniqueTerms);
  }
  
  function SolutionsSmall() {
    return (
      languageMap().filter(card => props.solutionsPath?.includes(card.contentItemId)).reverse().map(listitem => {
        return (
          <div className={solutionStyles.solutionContainer} key={listitem.contentItemId}>
            <button
              tabIndex="0" 
              className={solutionStyles.solutionButton} 
              key={listitem.contentItemId}
              onClick={() => handleOption(listitem)}>
                {listitem.title}
            </button>
          </div>
        )
      })
    )
  };

  function NoChildren(props) {
    const solution = props.solution;
    return(
      <React.Fragment key={solution.contentItemId}>
        <div className={[solutionStyles.latest, 'latest-solution'].join(' ')}></div>
        <div className={solutionStyles.containeryellow}>
          <SanitizedHTML allowedAttributes={allowedHtml.allowedAttributes} allowedTags={allowedHtml.allowedTags} allowedIframeHostnames={allowedHtml.allowedIframeHostnames} html={solution.body}/>
        </div>
      </React.Fragment>
    )
  }

  function HasChildren(props){
    const solution = props.solution;
    
    return(
      <React.Fragment key={solution.contentItemId}>
        <SanitizedHTML allowedAttributes={allowedHtml.allowedAttributes} allowedTags={allowedHtml.allowedTags} allowedIframeHostnames={allowedHtml.allowedIframeHostnames} html={solution.body}/>
      
        <strong style={{ display: `block`, textAlign: `center`, paddingTop: `2rem` }}>
          {selectText()}
          <svg xmlns="http://www.w3.org/2000/svg" width="14px" height="14px" data-name="Layer 1" viewBox="0 0 12.75 16.84"><defs/><path fill="none" stroke="#202020" strokeWidth="'2'" d="M12 9.77l-5.63 5.66L.71 9.77M6.37 0v15.23"/></svg>
        </strong>
        <div className="button-options" style={{display: `flex`, justifyContent: `center`, marginTop: `2em`, flexWrap: `wrap`}}>
            {solution.children.map(childId => {
              const child = languageMap().filter(solution => solution.contentItemId === childId)[0]
              return(
                <button 
                  onClick={() => handleSolutionChildren({...child, parent: solution.contentItemId}) }
                  className={`${solutionStyles.btn} ${solutionTree.filter(sol => sol.parent === solution.contentItemId && sol.contentItemId === child.contentItemId).length > 0 ? solutionStyles.btnYellow : '' }`}
                  key={`${solution.contentItemId}-${child.contentItemId}`}>
                    <SanitizedHTML html={child.title} />
                </button>
              )
            })}
          </div>
          <hr style={{borderBottom: '0', marginTop: '2em', marginBottom: '2em'}} className="latest-solution"/>
      </React.Fragment>
    )
  }

  function Glossary() {
    if(glossary.length > 0){
      return (
        <React.Fragment>
          <div className={solutionStyles.containergray}>
            <h2 className="margin-top-0" style={{fontSize: `16px`}}>{wordGlossary()}</h2>
            {glossary.map(word => {
              return(
                <div style={{marginBottom: `1rem`, fontSize: `14px`}} key={word.id}>
                  <strong>{word.term}: </strong>
                  <SanitizedHTML html={word.definition} style={{ marginTop: '-.5rem'}}/>
                </div>
              )
            })}
          </div>
        </React.Fragment>
      );
    } else {
      return(null)
    }
  }

  function Tree() {
    if(solutionTree.length > 0){
      return (solutionTree.map(solution => {
        if(solution.children.length === 0) {
          return <NoChildren solution={solution} key={solution.contentItemId}/>;
        } else {
          return <HasChildren solution={solution} key={solution.contentItemId}/>;
        }
      }))
    } else {
      return(null)
    }
  }

  function closeModal(){
    setIsOpen(false);
    //clear state
    setSolutionTree([]);
    setTitle('');
    setGlossary([]);
  }

  return(
    <div className="grid-container" id="tree">
      <section className={solutionStyles.card}>
          <h3 className="font-serif-lg tablet:font-serif-2xl margin-0 margin-bottom-3">{props.solutionsTitle}</h3>
          <div className="grid-row">
            <SolutionsSmall/>
          </div>
      </section>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        shouldCloseOnOverlayClick={false}
        style={customStyles}
        ref={modalRef}
        // contentLabel={solutionPathTitles[props.solutionsPath]}
        id="solution-modal">
          <div className={solutionStyles.modalHeader}>
            <h2>{title}</h2>
            <button type="button" className={solutionStyles.modalClose} onClick={closeModal}>
            <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 64 64"><title><FormattedMessage id="headerClose" /></title><path fill="#ffffff" fillRule="evenodd" d="M57.042 1.15l5.809 5.808a4 4 0 0 1 0 5.657L43.465 32l19.386 19.385a4 4 0 0 1 0 5.657l-5.809 5.809a4 4 0 0 1-5.657 0L32 43.465 12.615 62.851a4 4 0 0 1-5.657 0l-5.809-5.809a4 4 0 0 1 0-5.657L20.535 32 1.149 12.615a4 4 0 0 1 0-5.657l5.809-5.809a4 4 0 0 1 5.657 0L32 20.535 51.385 1.149a4 4 0 0 1 5.657 0z"/></svg>
            </button>
          </div>
          <div id="modal-tree" className={`padding-x-3 tablet:padding-5 tablet:padding-x-9 margin-x-auto margin-y-0 ${solutionStyles.modalTree} `} style={{maxWidth: `130ex`}}>
            <Tree/>
            <Glossary/>
          </div>
          <ScrollToTop el="solution-modal" />
      </Modal>
    </div>
  )
 
}