import React, {useState, useEffect } from "react"
import { useIntl } from "gatsby-plugin-intl"
import "uswds/dist/css/uswds.min.css"
import "uswds/dist/js/uswds.js"
import Header from "../components/header"
import Closures from "../components/closures"
import Hero from "../components/hero"
import Explore from "../components/explore"
import Solutions from "../components/solutions"
import Footer from "../components/footer"
import Disclaimer from "../components/disclaimer"
import ScrollToTop from "../components/scrollToTop"
import { graphql, useStaticQuery } from "gatsby"
import "./styles.scss"

export default function Home() {
  const intl = useIntl();

  const cardData =  useStaticQuery(graphql`
    query {
        cards {
            cards(orderBy:{latest:DESC}) {
                contentItemId
                displayText
                titleEnglish
                titleSpanish
                titleHaitianCreole
                titlePortuguese
                titlePunjabi
                bodySpanish
                bodyEnglish
                bodyHaitianCreole
                bodyPortuguese
                bodyPunjabi
                buttonEnglish
                buttonSpanish
                buttonHaitianCreole
                buttonPortuguese
                buttonPunjabi
                solutions {
                    contentItemIds
                }
            }
        }
    }
  `);

  function languageMap() {
    switch (intl.locale) {
      case 'es':
        return cardData.cards.cards.map(card => ({
          id: card.contentItemId,
          title: card.titleSpanish,
          body: card.bodySpanish,
          button: card.buttonSpanish,
          solutions: card.solutions.contentItemIds
        }))
      case 'zh':
        return cardData.cards.cards.map(card => ({
          id: card.contentItemId,
          title: card.titleChinese,
          body: card.bodyChinese,
          button: card.buttonChinese,
          solutions: card.solutions.contentItemIds
        }))
      case 'ht':
        return cardData.cards.cards.map(card => ({
          id: card.contentItemId,
          title: card.titleHaitianCreole,
          body: card.bodyHaitianCreole,
          button: card.buttonHaitianCreole,
          solutions: card.solutions.contentItemIds
        }))
      case 'pt':
        return cardData.cards.cards.map(card => ({
          id: card.contentItemId,
          title: card.titlePortuguese,
          body: card.bodyPortuguese,
          button: card.buttonPortuguese,
          solutions: card.solutions.contentItemIds
        }))
      case 'pa':
        return cardData.cards.cards.map(card => ({
          id: card.contentItemId,
          title: card.titlePunjabi,
          body: card.bodyPunjabi,
          button: card.buttonPunjabi,
          solutions: card.solutions.contentItemIds
        }))
      default: 
        return cardData.cards.cards.map(card => ({
          id: card.contentItemId,
          title: card.titleEnglish,
          body: card.bodyEnglish,
          button: card.buttonEnglish,
          solutions: card.solutions.contentItemIds
        }))
    }
  }
  // let pathState = languageMap()[0].solutions;
  // let titleState = languageMap()[0].title;
  // let activeState = 0
  // const isBrowser = typeof window !== "undefined"
  // if (isBrowser) {
  //   const queryParams = new URLSearchParams(window.location.search);
  //   const cardId = queryParams.get('card');

  //   if(cardId !== null){
  //     const card = languageMap().find(card => card.id === cardId);
  //     const cardIndex = languageMap().findIndex(card => card.id === cardId)
  //     if(card !== undefined){
  //       pathState = card.solutions;
  //       titleState = card.title;
  //       activeState = cardIndex;
  //     }
  //   }
  // }
  

  const [solutionsPath, setSolutionsPath] = useState(languageMap()[0].solutions);
  const [solutionsTitle, setSolutionsTitle] = useState(languageMap()[0].title);
  const [active, setActive] = useState(0);

  useEffect(() => {
    const isBrowser = typeof window !== "undefined"
    if (isBrowser) {
      const queryParams = new URLSearchParams(window.location.search);
      const cardId = queryParams.get('card');
  
      if(cardId !== null){
        const card = languageMap().find(card => card.id === cardId);
        const cardIndex = languageMap().findIndex(card => card.id === cardId)
        if(card !== undefined){
          setSolutionsPath(card.solutions);
          setSolutionsTitle(card.title);
          setActive(cardIndex);
        }
      }
    }
  }, [])

  return (
    <div>
      <Header />
      {/* <Closures /> */}
      <main id="main-content">
      <Hero />
      <Explore setSolutionsPath={setSolutionsPath} setSolutionsTitle={setSolutionsTitle} setActive={setActive} active={active}/>
      <Solutions solutionsPath={solutionsPath} solutionsTitle={solutionsTitle} />
      </main>
      <Footer />
      <Disclaimer />
      <ScrollToTop />
    </div>
  )
}